<template>
  <select-popup :size="size" :closePopup="closePopup" class="popup-select menu-popup">
    <div v-if="mobileView" class="mobile-header">
      <div class="header">Menu</div>
      <div class="close-div" @click="closePopup()">
          <img
              src="@/assets/icons/detail_close_icon.svg"
              alt="closes"
              class="header-close"
              loading="lazy"
            />
      </div>
    </div>
    <ul class="menu-ul">
      <li
        class="row menu-row"
        v-for="(menu, index) in menuItems"
        :key="index"
        :id="menu.id"
        :class="isActive(menu.id)"
        v-show="isVisible(menu)"
      >
        <div class="col-xlg-10 col-lg-10 col-sm-10 col-xs-10 col-md-10">
          <p class="meta-link" @click="getRouter(menu)">{{menu.title}}</p>
        </div>
      </li>
    </ul>
  </select-popup>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import GoogleAnalytics from "@/mixins/GoogleAnalytics";

import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";

export default {
  name: "Menu",
  props: ["size", "closePopup"],
  data() {
    return {
      showLoading: false,
      menuItems: [],
      loginstatus: false,
      mobileView:false
    };
  },
  watch: {
    subscriberId() {
      if (this.subscriberId) {
        this.loginstatus = true;
      }
    }
  },
  computed: {
    ...mapGetters(["subscriberId", "appUser", "appMenu"]),
  },
  methods: {
    getmenuItems() {
      if (this.appMenu) {
        let menu = this.appMenu;
        for (var i in menu) {
          if (menu[i].groupId && menu[i].groupId === 2) {
            this.menuItems.push(menu[i]);
          }
        }
        // console.log("menu items ", this.menuItems);
      }
    },
    isVisible(items) {
      if (items.loginRequired) {
        return this.loginstatus;
      }
      return true;
    },
    getRouter(menu) {
      let menuItem;
      menuItem = menu.title;
      if (menu.title == "Home") {
        this.$router.push({ name: "Home" });
      } else if (menu.title == "Watchlist") {
        this.$router.push({ name: "WatchList" });
      }
      else if(menu.title == 'Purchased'){
        this.$router.push('/profile#purchased')
      } 
      else {
        menuItem = menu.id;
        let menuId = menu.id.toLowerCase();
        this.$router.push({
          name: "ContentList",
          params: { contentType: menuId }
        });
      }
      let GA = {
        MenuItem: menuItem
      };
      this.MenuEvent(GA);

      this.closePopup();
    },
    isActive(id) {
      let name = undefined;
      let routeName = this.$route.name;
      if (routeName === "ContentList") {
        name = this.$route.params.contentType;
      } else if (routeName === "Home" || routeName === "WatchList") {
        name = routeName.toLowerCase();
      }
      if (id.toLowerCase() === name) return "active-menu";
    },
    mobileViewToggle(val){
      this.mobileView=val;
    }
  },
  created() {
    if (this.subscriberId) {
      this.loginstatus = true;
    }
    this.getmenuItems();
    EventBus.$on('toggle-dropdown-view',this.mobileViewToggle);
  },
  beforeDestroy(){
     EventBus.$off('toggle-dropdown-view',this.mobileViewToggle);
  },
  components: {
    "select-popup": () =>
      import(
        /* webpackChunkName: "selectPopup" */ "@/components/Shared/templates/selectPopup.vue"
      )
  },
  mixins: [appMixins, GoogleAnalytics]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.mobile-header{
  display:flex;
  flex-flow:row nowrap;
  justify-content: space-between;
  align-items: center;
  height:50px;
  padding: 0 15px;
  .header{
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 2rem;
    color: $font-clr-gray;
  }
}
.menu-ul {
  padding: 10px 15px;
  .meta-link {
    cursor: pointer;
    font-size: 16px;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    color: $font-clr-gray-1;
    line-height: 27px;
  }
  &:hover li{   
    background: inherit;
      // .meta-link{ 
      //   color: $font-clr-gray-1; 
      //   }
  }
  li:hover{
      background: $clr-bg-gray-dark 0% 0% no-repeat padding-box;
      // .meta-link{ 
      //   color: $font-clr-gray; 
      //   }
  }
}
.menu-row {
  padding: 10px 5px;
  opacity: 1;
}
.active-menu {

   .meta-link{ 
     color: $font-clr-gray;
     
     }

  background: $clr-bg-gray-dark 0% 0% no-repeat padding-box;
  // li:nth-last-child(1),
  // li:first-child {
  //   border-top-left-radius: 5px;
  //   border-top-right-radius: 5px;
  // }
}
</style>